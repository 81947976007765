<template>
  <div>
    <div class="formgrid grid">
      <div class="field col-12">
        <DataTable
          v-model:selection="selected"
          v-model:filters="filters"
          :value="list_po"
          :globalFilterFields="['pr_no', 'item_name']"
          :rowClass="rowClass"
          dataKey="id"
          responsiveLayout="scroll"
          editMode="cell" 
          @cell-edit-complete="onCellEditComplete"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Filter item"
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em" />
          <Column
            field="pr_no"
            header="No Pembelian"
            style="min-width: 15rem"
          />
          <Column
            field="item_barcode_1"
            header="Barcode"
            style="min-width: 8rem"
          />
          <Column
            field="item_name"
            header="Deskripsi"
            style="min-width: 12rem"
          />
          <Column
            field="qty"
            header="QTY PO"
            style="min-width: 8rem; justify-content: flex-end"
          />
          <Column field="qty_edit" header="qty" style="max-width: 6rem">
            <template #editor="{ data }">
              <div class="p-inputgroup flex-1">
                <InputNumber
                  ref="qty_edit"
                  v-model="data.qty_edit"
                  class="w-full"
                  :min="0"
                  :max="999999"
                  locale="id-ID"
                  input-class="w-full"
                  @focus="onFocusEdit($event)"
                  @blur="onBlurEdit()"
                  @input="onQtyEditInput($event, data)"
                  @keydown.enter="onBlurEdit()"
                  autofocus
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end align-items-end">
      <Button
        label="Tutup"
        icon="pi pi-times"
        class="p-button-text mr-2"
        @click="$emit('close')"
      />
      <Button
        :disabled="disabledSave"
        label="Tambahkan"
        icon="pi pi-check"
        @click="add"
      />
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers'
import { FilterMatchMode, FilterOperator } from 'primevue/api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pr_no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      disabledSave: true,
      editing: false,
      editingRows: [],
    }
  },
  watch: {
    selected(val) {
      this.disabledSave = !val || val.length === 0 || this.editing
      this.setEditingRows(val)
    },
    editing(val) {
      this.disabledSave = val || !this.selected || this.selected.length === 0
    },
  },
  computed: {
    list_po() {
      let items = []
      this.purchaseOrder.forEach((po) => {
        po.details.forEach((el) => {
          el.pr_no = po.pr_no
          el.pr_id = po.id
          el.prd_id = el.id
          el.id = `${po.id}${el.id}`
          el.qty_edit = 0
          items.push({ ...el })
        })
      })
      const result = items.slice()
      return result.filter((item) => {
        return this.excludeList.indexOf(item.id) === -1
      })
    },
  },
  methods: {
    add() {
      this.selected = this.selected.map((selected) => {
        if (selected.qty_edit == 0 || selected.qty_edit == null) {
          selected.qty = 0
          selected.qty_s = 0
        }
        selected.qty_b = 0
        return {...selected}
      })
      this.$emit('save', this.selected)
    },
    formatCurrency(value) {
      value = value ? value : 0
      return formatCurrency(value, 0, 0)
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onCellEditComplete(event) {
      let { data, newValue } = event

      const index = this.selected.findIndex((item) => item.id === data.id)
      if (index !== -1) {
        this.selected[index].qty_edit = newValue
        this.selected[index].qty_s = newValue
      }

      const idx = this.list_po.findIndex((item) => item.id === data.id)

      if (idx !== -1) {
        this.list_po[idx].qty_edit = newValue
        this.list_po[idx].qty_s = newValue
      }
    },
    onQtyEditInput(event, data) {
      if (event.value > 0) {
        if (!this.selected.some((item) => item.id === data.id)) {
          this.selected = [...this.selected, data]
        }
      } else {
        this.selected = this.selected.filter((item) => item.id !== data.id)
      }

      const idx = this.list_po.findIndex((item) => item.id === data.id)
      if (idx !== -1) {
        this.list_po[idx].qty_b = 0
        this.list_po[idx].qty_s = data.qty_edit
        this.list_po[idx].qty_edit = data.qty_edit
      }
    },
    isRowSelected(rowData) {
      return (
        this.selected &&
        this.selected.some((selectedRow) => selectedRow.id === rowData.id)
      )
    },
    rowClass(data) {
      return {
        'bg-blue-400': this.isRowSelected(data),
      }
    },
    onFocusEdit(event) {
      event.target.select()
      this.editing = true
    },
    onBlurEdit() {
      this.editing = false
    },
    setEditingRows(rows) {
      this.editingRows = rows.map(row => row.id)
    }
  }
}
</script>
