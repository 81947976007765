<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Penerimaan - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Pembelian / Penerimaan / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            type="submit"
          />
          <Button
            :disabled="old_status_value === 'posted'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            @click="onAddData"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'PurchaseReceive' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-8">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>No Penerimaan</label>
                <InputText
                  v-model="form.rcv_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Supplier</label>
                <Dropdown
                  ref="supplierDropdown"
                  v-model="form.supp_id"
                  :filter="true"
                  :options="list_supplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Pilih Supplier"
                  :disabled="id"
                  :class="{
                    'p-invalid': v$.form.supp_id.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.supp_id.$invalid && submitted) ||
                    v$.form.supp_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supp_id.required.$message }}</small
                >
              </div>

              <div class="field col-12 md:col-4">
                <label>Tgl Terima</label>
                <Calendar
                  v-model="form.rcv_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                />
              </div>

              <div class="field col-12 md:col-4">
                <label>TGL SJ</label>
                <Calendar
                  v-model="form.sj_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  input-class="w-full"
                  :show-current="false"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No SJ</label>
                <InputText
                  v-model="form.sj_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                />
              </div>

              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  disabled
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>DISC Reguler</label>
                <div class="p-inputgroup flex-1">
                  <span v-if="disc_reg > 100" class="p-inputgroup-addon">
                    Rp
                  </span>
                  <InputNumber
                    ref="disc_reg"
                    v-model="disc_reg"
                    locale="id-ID"
                    class="w-full"
                    input-class="w-full"
                    :min="0"
                    :minFractionDigits="0"
                    :maxFractionDigits="2"
                    @update:model-value="changeDiscReg"
                    @input="disc_reg = $event.value"
                    @focus="selectAllText('disc_reg')"
                  />
                  <span v-if="disc_reg <= 100" class="p-inputgroup-addon">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <fx-table
            :items="form.details"
            :total="form.details.length"
            :loading="isLoading"
            :paginator="false"
            hasFooter
            showGridlines
          >
            <template #columns>
              <Column
                field="pr_no"
                header="No Pembelian"
                style="min-width: 12rem; background-color: rgb(248, 249, 250)"
              />
              <Column
                field="item_sku"
                header="SKU"
                class="bg-gray"
                style="min-width: 8rem; background-color: rgb(248, 249, 250)"
              />
              <Column
                field="item_barcode_1"
                header="Barcode"
                class="bg-gray"
                style="min-width: 8rem; background-color: rgb(248, 249, 250)"
              />
              <Column
                field="item_name"
                header="Deskripsi"
                class="bg-gray"
                style="min-width: 12rem; background-color: rgb(248, 249, 250)"
              />

              <Column
                field="unit_b"
                header="Satuan"
                class="bg-gray"
                style="min-width: 6rem; background-color: rgb(248, 249, 250)"
              >
                <template #body="slotProps">
                  {{ slotProps.data.unit_b }}.{{ slotProps.data.unit_s }}
                </template>
              </Column>
              <Column
                field="ubox"
                header="ISI"
                class="bg-gray"
                style="min-width: 3rem; justify-content: flex-end"
              />
              <Column
                field="qty_b"
                header="qty (b)"
                class="bg-gray"
                style="min-width: 3rem; justify-content: flex-end"
              />

              <Column
                field="qty_s"
                header="qty (s)"
                style="width: 4rem; justify-content: flex-end"
              />
              <Column
                field="qty"
                header="QTY (PCS)"
                class="bg-gray"
                style="
                  min-width: 5rem;
                  background-color: rgb(248, 249, 250);
                  justify-content: flex-end;
                "
              >
              </Column>

              <Column
                field="cost"
                header="Harga"
                style="min-width: 10rem; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.cost) }}
                </template>
              </Column>
              <Column
                field="cost_per"
                header="PER"
                style="min-width: 4rem; justify-content: flex-start"
              >
              </Column>

              <Column
                field="disc_reg"
                header="DISC.REG"
                style="min-width: 8rem; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{
                    slotProps.data.disc_reg > 100
                      ? formatCurrency(slotProps.data.disc_reg)
                      : `${slotProps.data.disc_reg || 0}%`
                  }}
                </template>
              </Column>
              <Column
                field="disc_ext"
                header="DISC.EXT"
                style="min-width: 6rem; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{
                    slotProps.data.disc_ext > 100
                      ? formatCurrency(slotProps.data.disc_ext || 0)
                      : `${slotProps.data.disc_ext || 0}%`
                  }}
                </template>
              </Column>

              <Column
                field="tax"
                header="PPN"
                style="min-width: 3rem; justify-content: flex-end"
              >
                <template #body="slotProps">
                  <span style="text-align: right">
                    {{ slotProps.data.tax }}%
                  </span>
                </template>
              </Column>

              <Column
                field="dpp"
                header="Jumlah"
                class="bg-gray"
                style="
                  min-width: 8rem;
                  justify-content: flex-end;
                  background-color: rgb(248, 249, 250);
                "
              >
                <template #body="slotProps">
                  {{ formatCurrency(slotProps.data.bruto) }}
                </template>
              </Column>
              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="
                      p-button-outlined p-button-secondary p-button-text
                      mr-2
                    "
                    :disabled="old_status_value === 'posted'"
                    @click="onEditData(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-outlined p-button-danger p-button-text"
                    :disabled="old_status_value === 'posted'"
                    @click="onConfirmDeletion(data)"
                  />
                </template>
              </Column>
            </template>
            <template #footer>
              <table class="flex justify-content-end text-grey-200">
                <tr>
                  <td width="100%">
                    <table class="justify-content-end text-700">
                      <tr>
                        <td>SUB TOTAL</td>
                        <td style="width: 150px" class="text-right">
                          {{ formatCurrency(totalPO.bruto || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC REGULER</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.disc_reg || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC EXTRA</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.disc_ext || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>PPN</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.ppn || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>GRAND TOTAL</td>
                        <td class="text-right">
                          {{ formatCurrency(totalPO.total || 0) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </fx-table>
        </div>
      </div>
    </form>
    <Dialog
      :header="'Tambah Item Penerimaan'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '95vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-purchase-order-receive
        :purchaseOrder="list_purchase_order"
        :excludeList="list_produk"
        :editMode="editMode"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Edit Item Penerimaan"
      v-model:visible="dialogItem"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-purchase-order
        :item="formDetail"
        :loading="isLoadingSave"
        :list-satuan="list_satuan_unit"
        :purchase-order="PurchaseOrder"
        :editMode="editMode"
        :isDppMinus="isDppMinus"
        :isHideCheckBox="true"
        onMenu="receive"
        @save="onSaveDataItem"
        @close="dialogItem = false"
      />
    </Dialog>
    <Dialog
      header="Perubahan Supplier"
      v-model:visible="dialogSupplier"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :closable="false"
    >
      <div class="confirmation-content">
        <span>Item yang sudah diinput berbeda supplier, Ulangi Input ?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="onNoSupplier(this.old_supplier_id)"
        />
        <Button label="Yes" @click="onYesSupplier" />
      </template>
    </Dialog>
    <Dialog
      header="Hapus Penerimaan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Penerimaan <strong>{{ formDetail.item_name }}</strong> akan
          dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemPurchaseOrder"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SettingService from '@/services/SettingService'
import ItemService from '@/services/ItemService'
import SatuanService from '@/services/SatuanService'
import TermService from '@/services/TermService'
import PurchaseOrderService from '@/services/PurchaseOrderService'
import PurchaseReceiveService from '@/services/PurchaseReceiveService'
import SupplierService from '@/services/SupplierService'
import FormPurchaseOrder from '@/components/pembelian/FormPurchaseOrder'
import FormPurchaseOrderReceive from '@/components/pembelian/FormPurchaseOrderReceive'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, ceiling } from '@/helpers/index'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import dayjs from 'dayjs'
// import Badge from 'primevue/badge'
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'PurchaseReceive',
  components: {
    FormPurchaseOrder,
    FormPurchaseOrderReceive,
    FxTable,
    Hotkey,
    // Badge,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      submitted: false,
      settingService: null,
      itemService: null,
      satuanService: null,
      termService: null,
      supplierService: null,
      purchaseOrderService: null,
      purchaseReceiveService: null,
      dialog: false,
      dialogItem: false,
      dialogHapus: false,
      dialogSupplier: false,
      isLoading: false,
      isLoadingItem: false,
      isLoadingSave: false,
      isSetting: false,
      isDppMinus: false,
      editMode: false,
      old_status_value: 'draft',
      old_supplier_id: 0,
      tax: 0,
      disc_reg: null,
      items: [],
      list_supplier: [],
      list_term: [],
      list_satuan: [],
      list_satuan_unit: [],
      list_items: [],
      list_global_setting: [],
      list_purchase_order: [],
      list_details_purchase: [],
      form: {
        rcv_no: '',
        sj_no: '',
        rcv_at: new Date(),
        sj_at: null,
        supp_id: '',
        // whouse: 0,
        status: 'draft',
        details: [],
      },
      formDetail: {},
      isCheck: false,
      listDeleted: [],
      PurchaseOrder: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    roles() {
      const user = JSON.parse(localStorage.getItem('user'))
      const roles = user.roles

      if (roles && roles.length > 0) {
        return roles.map((item) => item.name)
      }
      return []
    },

    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'printed', label: 'Printed', level: 0 },
        { value: 'posted', label: 'Posted', level: 1 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: status.disabled
          ? status.disabled
          : old_status_level > status.level,
      }))
    },
    list_produk() {
      return this.form.details.map((x) => `${x.pr_id}${x.prd_id}`)
    },
    totalPO() {
      // Calculate purchase.disc_reg
      let discRegTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_regv
        return total
      }, 0)

      let discExtTotal = this.form.details.reduce((total, detail) => {
        total += detail.disc_extv
        return total
      }, 0)

      // jumlah bruto
      let bruto = this.form.details.reduce((total, detail) => {
        total += detail.bruto
        return total
      }, 0)

      // Calculate total_dpp
      let dpp = bruto - discRegTotal - discExtTotal

      let total_ppn = this.form.details.reduce((total, detail) => {
        total += Math.floor(
          (detail.bruto - detail.disc_regv - detail.disc_extv) *
            (detail.tax / 100)
        )
        return total
      }, 0)

      total_ppn = Math.floor(total_ppn)

      const total = {
        bruto: bruto,
        dpp: dpp,
        disc_reg: discRegTotal,
        disc_ext: discExtTotal,
        ppn: total_ppn,
        total: dpp + total_ppn,
      }
      return total
    },
  },
  watch: {
    'form.supp_id'(value) {
      if (this.form.details.length > 0) {
        this.purchaseOrderService
          .getById(`${this.form.details[0].pr_id}`)
          .then((res) => {
            if (res.data.data.supp_id !== value) {
              this.dialogSupplier = true
              this.old_supplier_id = res.data.data.supp_id
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Supplier', this)
          })
      }
    },
  },
  created() {
    this.purchaseOrderService = new PurchaseOrderService()
    this.purchaseReceiveService = new PurchaseReceiveService()
    this.termService = new TermService()
    this.supplierService = new SupplierService()
    this.satuanService = new SatuanService()
    this.itemService = new ItemService()
    this.settingService = new SettingService()
  },
  async mounted() {
    if (this.id > 0) {
      this.isSetting = true
      this.loadData()
      this.isSetting = false
    }

    this.settingService
      .get()
      .then((res) => {
        this.list_global_setting = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.supplierService
      .getActive()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.termService
      .get()
      .then((res) => {
        this.list_term = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Termin', this)
      })

    this.satuanService
      .get()
      .then((res) => {
        this.list_satuan = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Satuan', this)
      })
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    totalDetail(detail) {
      let qty_b = detail['qty_b']
      let qty_s = detail['qty_s']

      // hitung total qty
      let qty = 0
      if (qty_b > 0) {
        qty = detail['ubox'] * qty_b
      }
      qty += qty_s

      // hitung total bruto dan harga per unit
      let bruto = 0
      let cost_s = 0
      if (detail['cost_per'] === detail['unit_b']) {
        // bruto = (detail['cost'] / detail['ubox']) * qty
        // cost_s = detail['cost'] / detail['ubox']
        bruto = Math.round(detail['cost'] / detail['ubox']) * qty
        cost_s = Math.round(detail['cost'] / detail['ubox'])
      } else {
        // bruto = detail['cost'] * qty
        // cost_s = detail['cost']
        bruto = Math.round(detail['cost']) * qty
        cost_s = Math.round(detail['cost'])
      }

      //  round bruto and cost per unit
      // bruto = Number(bruto.toFixed(2))
      // cost_s = Number(cost_s.toFixed(2))

      //  disc reg
      let disc_regv = 0
      let disc_reg = detail.disc_reg
      if (disc_reg <= 1.0 || disc_reg <= 100.0) {
        // disc_regv = Number((cost_s * (disc_reg / 100)).toFixed(2))
        disc_regv = Math.round(cost_s * (disc_reg / 100))
      } else if (disc_reg > 100.0) {
        // disc_regv = Number(disc_reg.toFixed(2))
        disc_regv = Math.round(disc_reg.toFixed(2))
      }

      cost_s -= disc_regv
      if (cost_s < 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Order Pembelian',
          detail: 'Total diskon reguler tidak dapat melebihi harga satuan.',
          life: 3000,
        })
        return
      }

      // total diskon reg
      disc_regv *= qty

      //  disc ext
      let disc_extv = 0
      let disc_ext = detail.disc_ext
      if (disc_ext <= 1 || disc_ext <= 100.0) {
        // disc_extv = Number((cost_s * (disc_ext / 100)).toFixed(2))
        disc_extv = Math.round(cost_s * (disc_ext / 100))
      } else if (disc_ext > 100.0) {
        // disc_extv = Number(disc_ext.toFixed(2))
        disc_extv = Math.round(disc_ext.toFixed(2))
      }

      cost_s -= disc_extv
      if (cost_s < 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Order Pembelian',
          detail: 'Total diskon extra tidak dapat melebihi harga satuan.',
          life: 3000,
        })
        return
      }

      // total diskon ext
      disc_extv *= qty

      // total dpp
      let dpp = cost_s * qty
      let total = dpp

      // price
      let price = Number((cost_s / (1 - detail['margin_pst'] / 100)).toFixed(2))
      let ppn = Math.floor(price * (this.form.tax / 100))
      price = ceiling(price + ppn)

      //margin
      let margin = ceiling(detail['price'])

      //mark = 0 || default
      //mark = 1 || Hrg. Jual Skrg < Hrg. Jual Baru
      //mark = 2 || Hrg. Jual Baru < Hrg. Jual Skrg
      //mar  = 3 || Item dengan perubahan harga
      let mark = detail.mark || 0
      mark = margin < price ? 1 : price < margin ? 2 : 0

      return {
        qty: qty,
        bruto: bruto,
        dpp: dpp,
        // ppn: ppn,
        mark: mark,
        margin: margin,
        total: total,
        price: price,
        disc_regv: disc_regv,
        disc_extv: disc_extv,
      }
    },
    changeDiscReg() {
      if (this.isSetting == false) {
        this.form.details.forEach((item) => {
          item.disc_reg = this.disc_reg
          const totalDetail = this.totalDetail(item)
          Object.assign(item, { ...item, ...totalDetail })
        })
      }
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const rcv_at = this.form.rcv_at
        ? { rcv_at: dayjs(this.form.rcv_at).format('YYYY-MM-DD') }
        : null

      const sj_at = this.form.sj_at
        ? { sj_at: dayjs(this.form.sj_at).format('YYYY-MM-DD') }
        : null

      const form = {
        ...rcv_at,
        // ...sj_at,
        sj_at: this.form.sj_at != null ? sj_at : null,
        sj_no: this.form.sj_no,
        rcv_no: this.form.rcv_no,
        supp_id: this.form.supp_id,
        // whouse: this.form.whouse,
        status: this.form.status,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            prd_id: item.prd_id,
            qty_b: item.qty_b,
            qty_s: item.qty_s,
            cost: item.cost,
            disc_reg: item.disc_reg,
            disc_ext: item.disc_ext,
            tax: item.tax,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        form.sj_at =
          this.form.sj_at != null
            ? dayjs(this.form.sj_at).format('YYYY-MM-DD')
            : null
        this.purchaseReceiveService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Penerimaan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })

              this.$router.push({ name: 'PurchaseReceive' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Penerimaan', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        form.sj_at =
          this.form.sj_at != null
            ? dayjs(this.form.sj_at).format('YYYY-MM-DD')
            : null
        this.purchaseReceiveService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Penerimaan',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              if (this.form.status === 'printed') {
                this.$router.push({ name: 'PurchaseReceive' })
              }
            }
          })
          .catch((err) => {
            errorHandler(err, 'Penerimaan', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },

    loadData() {
      this.isLoading = true
      this.purchaseReceiveService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.form.rcv_at = this.form.rcv_at ? new Date(this.form.rcv_at) : ''
          this.old_status_value = this.form.status
        })
        .catch((err) => {
          errorHandler(err, 'Data Penerimaan', this)
        })
        .finally(() => (this.isLoading = false))
    },

    onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.purchaseOrderService
        .getById(this.formDetail.pr_id)
        .then((res) => {
          this.list_details_purchase = res.data.data.details

          const idx = this.list_details_purchase.findIndex(
            (item) => item.item_id === this.formDetail.item_id
          )
          if (idx !== -1) {
            const detailsPurchase = this.list_details_purchase[idx]
            this.list_satuan_unit = this.list_satuan.filter(
              (item) =>
                item.sat === 'PCS' ||
                item.sat === detailsPurchase.unit_b ||
                item.sat === detailsPurchase.unit_s ||
                item.sat === this.formDetail.unit_b ||
                item.sat === this.formDetail.unit_s
            )
          } else {
            this.list_satuan_unit = this.list_satuan
          }
          this.dialogItem = true
          this.isLoading = false
        })
        .catch(() => {
          // this.isLoadingItem = false
        })
        .finally(() => (this.isLoading = false))
    },
    onAddData() {
      this.editMode = false

      if (!this.form.supp_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Penerimaan',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const supplier = this.list_supplier.filter(
        (item) => item.id === this.form.supp_id
      )[0]
      this.isLoadingItem = true
      this.purchaseOrderService
        .getItemPO(`?status=done&supp_id=${this.form.supp_id}&receive=0`)
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Penerimaan',
              detail: `Penerimaan untuk pelanggan ${supplier.name} tidak ditemukan!`,
              life: 3000,
            })
            this.isLoadingItem = false
          } else {
            this.list_purchase_order = res.data.data
            this.dialog = true
            this.isLoadingItem = false
          }
        })
        .catch(() => {
          this.isLoadingItem = false
        })
    },
    async onSaveDataItem(data) {
      const detailItem = Object.assign({}, data)
      const idx = this.form.details.findIndex(
        (item) =>
          item.pr_id === detailItem.pr_id && item.prd_id === detailItem.prd_id
      )

      const totalDetail = await this.totalDetail(detailItem)
      if (this.editMode) {
        if (totalDetail.dpp < 0) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Order Pembelian',
            detail: 'Total diskon tidak dapat melebihi nilai transaksi.',
            life: 3000,
          })
          this.isDppMinus = true
        } else {
          Object.assign(this.form.details[idx], {
            ...detailItem,
            ...totalDetail,
          })
          this.dialogItem = false
        }
      }
    },
    async onSaveData(data) {
      data.map(async (detailItem) => {
        const idx = this.form.details.findIndex(
          (item) =>
            item.pr_id === detailItem.pr_id && item.prd_id === detailItem.prd_id
        )
        const totalDetail = await this.totalDetail(detailItem)
        if (idx === -1) {
          delete detailItem.id

          this.form.details.push({ ...detailItem, ...totalDetail })
          this.$toast.add({
            severity: 'success',
            summary: 'Penerimaan',
            detail: 'Data Item berhasil ditambahkan.',
            life: 3000,
          })
          this.dialog = false
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Penerimaan',
            detail: `Item ${detailItem.no_po} sudah ada`,
            life: 3000,
          })
        }
      })
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemPurchaseOrder() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Penerimaan',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onYesSupplier() {
      this.form.details = []
      this.dialogSupplier = false
    },
    onNoSupplier(value) {
      this.$refs.supplierDropdown.filterValue = ''
      this.form.supp_id = value
      this.dialogSupplier = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },

    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {
      form: {
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },
      },
    }
  },
}
</script>
